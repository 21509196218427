import styled, { css } from 'styled-components'
import ImgBase from 'gatsby-image'

import { heading, paragraph } from 'styles/typography'
import theme from 'styles/theme'
import mq from 'styles/mq'

export const Header = styled.header`
  padding: 6rem 0 0;

  ${mq.medium} {
    padding: 15rem 0 2rem;
  }

  background-color: ${theme.color.background.light};
`

export const Headline = styled.h1`
  ${heading.xxxl};
  margin-bottom: 0;
`

export const Section = styled.section<{ hasBackground?: boolean }>`
  position: relative;
  padding: 8rem 0;

  ${mq.medium} {
    padding: 12rem 0;
  }

  ${props =>
    props.hasBackground &&
    css`
      background-color: white;
    `}
`

export const SectionTitle = styled.h2<{ indent?: boolean }>`
  ${heading.xl};
  max-width: 77rem;

  ${props =>
    props.indent &&
    css`
      text-indent: -0.5em;
    `}
`

export const Paragraph = styled.p`
  ${paragraph.small};
  max-width: 77rem;
`

export const Img = styled(ImgBase)<{ small?: boolean; medium?: boolean }>`
  ${props =>
    props.medium === false &&
    css`
      ${mq.medium} {
        display: none;
      }

      ${mq.large} {
        display: block;
      }
    `}

  ${props =>
    props.medium &&
    css`
      display: none;

      ${mq.medium} {
        display: block;
      }

      ${mq.large} {
        display: none;
      }
    `}
`

export const Intro = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-gap: 6rem;

  ${mq.medium} {
    align-items: center;
    grid-template-columns: auto;
    grid-gap: 9rem;
  }

  ${mq.large} {
    grid-template-columns: 1fr 54rem;
  }
`

export const ImgContainer = styled.div`
  position: relative;
  margin: 0 -3rem;
  grid-row: 1;

  ${mq.medium} {
    margin: 0;
  }

  ${mq.large} {
    grid-row: auto;
  }
`

export const IconFooterCTA = styled.div`
  display: grid;
  grid-gap: 2rem;

  ${SectionTitle} {
    ${heading.l};
    margin-bottom: 0;
  }

  svg {
    margin-left: -0.14em;
    font-size: 8.5rem;
    color: ${theme.color.accent.secondary};
  }

  ${mq.medium} {
    grid-template-columns: auto 1fr;
    grid-gap: 5rem;

    svg {
      font-size: 11rem;
    }
  }
`
