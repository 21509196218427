import React, { FC } from 'react'
import { navigate } from 'gatsby'

import Button from 'components/button'
import { useCartContext } from 'context/cart'
import Urls from 'common/urls'
import { formatCurrency } from 'utils/format'

type Props = {
  product: StripeSku
  CTA: string
  queryString?: string
}

const AddToCartButton: FC<Props> = ({ product, CTA, queryString }) => {
  const { updateItems } = useCartContext()
  const addItemAndGoToCart = async () => {
    updateItems({ [product.id]: 1 })
    dataLayer.push({
      event: 'eec.add',
      ecommerce: {
        add: {
          products: [
            {
              id: product.id,
              name: product.attributes.name,
              price: formatCurrency(product.price),
              quantity: 1,
            },
          ],
        },
      },
      // @ts-ignore
      branch: window.ab_branch, // branch is defined on the window in our SEO component
    })
    const path =
      typeof queryString !== 'undefined'
        ? `${Urls.cart}${queryString}`
        : Urls.cart
    await navigate(path)
  }
  return <Button onClick={addItemAndGoToCart}>{CTA}</Button>
}

export default AddToCartButton
