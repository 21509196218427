import styled from 'styled-components'
import { heading, paragraph } from 'styles/typography'
import theme from 'styles/theme'
import mq from 'styles/mq'

export const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${mq.medium} {
    flex-direction: row;
    align-items: baseline;
  }
`
export const Step = styled.div`
  padding: 4rem 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: space-between;
  min-height: 18rem;

  ${mq.medium} {
    padding-top: 0;
    flex-direction: column-reverse;
    text-align: center;
    flex: 1 1 0;
  }
`
export const StepContent = styled.div`
  width: 66%;

  ${mq.medium} {
    height: auto;
    width: 82%;
  }

  ${mq.large} {
    height: auto;
    width: 74%;
  }
`
export const StepNumber = styled.div`
  ${heading.xxl};
  text-decoration: underline;
  text-decoration-color: ${theme.color.accent.secondary};
`
export const StepTitle = styled.div`
  ${heading.xs};
  margin-top: 0.5em;
`

export const StepDetails = styled.p`
  ${paragraph.small};
  margin-top: 0.5em;
`

export const StepIcon = styled.div`
  color: ${theme.color.accent.secondary};
  font-size: 9rem;
  display: flex;
  height: 100%;
  width: 35%;

  ${mq.medium} {
    padding-bottom: 2rem;
    height: auto;
    width: 40%;
  }

  svg,
  .gatsby-image-wrapper {
    display: block;
    margin: auto;
    width: 100%;
  }
`
export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  background-color: white;

  ${mq.medium} {
    display: block;
    min-height: 45rem;
  }

  ${mq.large} {
    min-height: 45rem;
  }
`

export const ContentWrapper = styled.div`
  padding: 4rem 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${mq.medium} {
    padding: 4rem 6rem 0;
  }

  ${mq.large} {
    padding: 6rem 0 0;
  }
`
