import styled, { css } from 'styled-components'
import { heading, paragraph } from 'styles/typography'
import mq from 'styles/mq'
import theme from 'styles/theme'

export const Headline = styled.h1`
  ${heading.xxl};
  margin-bottom: 0;
`

export const Center = styled.div`
  max-width: 118rem;
  margin: auto;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const HeroWrap = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  padding: 4rem 3rem;

  ${mq.medium} {
    padding: 10rem 5rem;
  }
`

export const HeroCenter = styled(Center as any)`
  ${mq.large} {
    width: 118rem;
  }
`

export const CTAButtonWrapper = styled.div`
  text-align: center;
  margin-top: 4rem;

  ${mq.medium} {
    margin-top: 2rem;
  }
`

export const CenteredCTAButtonWrapper = styled.div`
  margin-top: 3rem;
  margin-bottom: 3rem;
  text-align: center;
`

export const Section = styled.section<{ hasBackground?: boolean }>`
  position: relative;
  padding: 6rem 0;

  ${mq.large} {
    padding: 8rem 0;
  }

  ${props =>
    props.hasBackground &&
    css`
      background-color: white;
    `}
`

export const SectionTitle = styled.h2`
  ${heading.l};
  text-align: center;

  ${mq.medium} {
    ${heading.xl};
  }
`

export const SectionTitleSmall = styled.h2`
  ${heading.m};
  text-align: left;
`
export const Triad = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 120rem;
  margin: 0 auto;

  ${mq.medium} {
    flex-direction: row;
  }
`

export const TriadItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;

  .gatsby-image-wrapper {
    width: 20rem;
    height: 16rem;
    margin: 0 auto;

    img {
      object-fit: cover;
    }
  }

  ${mq.medium} {
    width: 33%;
    padding: 0 2rem;
    margin-bottom: 0;
  }

  ${mq.large} {
    padding: 0 4rem;
  }
`

export const ItemTitle = styled.div`
  ${heading.s};
  line-height: 2.4rem;
  margin-bottom: 1rem;

  ${mq.desktop} {
    white-space: nowrap;
  }
`

export const ItemParagraph = styled.div`
  ${paragraph.small};

  ${mq.medium} {
    min-height: 16rem;
  }
`

export const ItemSubtitle = styled.div`
  ${heading.xxs};
  margin: 2rem 0;
  color: ${theme.color.accent.secondary};
  min-height: 2rem;
`

export const ItemTextContainer = styled.div`
  ${mq.medium} {
    min-height: 39rem;
  }

  ${mq.desktop} {
    min-height: 28rem;
  }
`