import styled from 'styled-components'
import mq, { MaxWidth } from 'styles/mq'

export default styled.div`
  width: 100%;
  max-width: ${MaxWidth / 10}rem;
  margin: 0 auto;
  padding: 0 2.4rem;

  ${mq.medium} {
    padding: 0 4.8rem;
  }
`
