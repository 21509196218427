import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const WellrootedLandingPage = () => (
  <PhysicianLandingPage
    physician="Dr. Pawlicki"
    institution="Wellrooted"
    referralCode="WELLROOTED"
    physicianURL="https://wellrootedpediatrics.com/"
  />
)

export default WellrootedLandingPage
