import React, { useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'

import SEO from 'components/seo'
import Layout from 'components/layout'
import Container from 'components/container'
import AddToCartButton from 'components/add-to-cart-button'
import {
  Headline,
  Section,
  SectionTitle,
  SectionTitleSmall,
  ContentWrapper,
  CTAButtonWrapper,
  CenteredCTAButtonWrapper,
  Triad,
  TriadItem,
  ItemSubtitle,
  ItemTitle,
  ItemParagraph,
  ItemTextContainer,
} from './styled'
import {
  Header,
  Paragraph,
  Intro,
  ImgContainer,
} from 'containers/about/styled'
import {
  StepWrapper,
  Step,
  StepContent,
  StepNumber,
  StepTitle,
  StepDetails,
  StepIcon,
} from 'containers/home/easy-steps/styled'
import { useSessionContext } from 'context/session'
import useAllKitProducts from 'hooks/use-all-kit-products'

type FluidImage = {
  childImageSharp: {
    fluid: FluidObject
  }
}

type QueryResult = {
  hero_no_discount: FluidImage
  hero_20: FluidImage
  hero_50: FluidImage
  smallCup: FluidImage
  box: FluidImage
  results: FluidImage
  kitImage: FluidImage
  kitPlusCryoImage: FluidImage
  vasectomyKitImage: FluidImage
}

const query = graphql`
  query {
    smallCup: file(relativePath: { eq: "small-cup.png" }) {
      childImageSharp {
        fluid(quality: 90, webpQuality: 100, maxWidth: 124) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    box: file(relativePath: { eq: "kit-closed.png" }) {
      childImageSharp {
        fluid(quality: 90, webpQuality: 100, maxWidth: 124) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    results: file(relativePath: { eq: "results-small.png" }) {
      childImageSharp {
        fluid(quality: 90, webpQuality: 100, maxWidth: 124) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    hero_no_discount: file(
      relativePath: { eq: "results-iphone-with-no-discount.png" }
    ) {
      childImageSharp {
        fluid(quality: 75, maxWidth: 528) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    hero_20: file(relativePath: { eq: "results-iphone-with-20-discount.png" }) {
      childImageSharp {
        fluid(quality: 75, maxWidth: 528) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    hero_50: file(relativePath: { eq: "results-iphone-with-50-discount.png" }) {
      childImageSharp {
        fluid(quality: 75, maxWidth: 528) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kitImage: file(relativePath: { eq: "kit-top-view.png" }) {
      childImageSharp {
        fluid(maxWidth: 170, webpQuality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kitPlusCryoImage: file(relativePath: { eq: "kit-plus-cryo-top-view.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, webpQuality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    vasectomyKitImage: file(relativePath: { eq: "vasectomy-kit-top-view.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, webpQuality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

type Props = {
  physician: string
  institution: string
  referralCode: string
  physicianURL?: string
  discountAmount?: string
  multiplePhysicians?: boolean
}

const PhysicianLandingPage = ({
  physician,
  institution,
  referralCode,
  physicianURL,
  discountAmount,
  multiplePhysicians,
}: Props) => {
  const data = useStaticQuery<QueryResult>(query)
  const { setReferralCode } = useSessionContext()
  const [kitProduct, kitPlusCryoProduct, vasectomyKitProduct] = useAllKitProducts()

  if (!discountAmount) {
    discountAmount = '20'
  }
  let hero = data.hero_no_discount.childImageSharp.fluid
  if (discountAmount === '20') {
    hero = data.hero_20.childImageSharp.fluid
  } else if (discountAmount === '50') {
    hero = data.hero_50.childImageSharp.fluid
  }

  useEffect(() => {
    setReferralCode(referralCode)
    gtag('event', 'Physician Page Visit', {
      event_category: 'Pageview',
      event_label: referralCode,
    })
  }, [referralCode, setReferralCode])

  return (
    <Layout>
      <SEO
        title="Recommended by your physician"
        description="Fellow helps doctors streamline their workflows and improve patient experience."
      />
      <Header>
        <Container>
          <Headline>
            Get {institution} approved clinical fertility results without
            leaving home.
          </Headline>
        </Container>
      </Header>

      <Section hasBackground>
        <Container>
          <Intro>
            <div>
              <SectionTitleSmall>Why {physician} recommend{multiplePhysicians ? '' : 's'}<span translate="no"> Fellow</span>:</SectionTitleSmall>

              <Paragraph>
                <span translate="no">Fellow </span>offers mail-in fertility tests that allow you to get
                the accuracy of a traditional lab test, without having to
                leave your home. Simply follow the instructions to produce
                your sample at home, mail it back, and we’ll analyze it in
                our CLIA-certified lab. We’ll send your comprehensive results to you and{' '}
                {physicianURL ? (
                  <a
                    href={physicianURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {physician}
                  </a>
                ) : (
                  physician
                )}
                {
                  physician.slice(-1) === '.' ?
                  '' : '.'
                }
              </Paragraph>
              <Paragraph>
                Use referral code <strong>{referralCode}</strong> when you check
                out, or order from this page to get ${discountAmount} off
                and connect your results to your doctor.
              </Paragraph>
            </div>
            <ImgContainer>
              <Img fluid={hero} />
            </ImgContainer>
          </Intro>
        </Container>
      </Section>

      <Section>
        <Container>
          <SectionTitle>
            Which test did {physician} recommend for you?
          </SectionTitle>
          <Triad>
            <TriadItem>
              <Img
                fluid={{
                  ...data.kitImage.childImageSharp.fluid
                }}
                alt="An image of the Fellow Semen Analysis kit"
              />
              <ItemTextContainer>
                <ItemSubtitle>Comprehensive Fertility Analysis</ItemSubtitle>
                <ItemTitle>
                  <span translate="no">Fellow </span>Semen Analysis
                </ItemTitle>
                <ItemParagraph>
                  Doctor-approved, clinical semen analysis results
                  with our at-home, mail-in kit. The<span translate="no"> Fellow </span>Semen
                  Analysis has peer-reviewed validation to exceed the gold
                  standard for semen analyses.
                </ItemParagraph>
              </ItemTextContainer>
              <CTAButtonWrapper>
                <AddToCartButton
                  product={kitProduct}
                  CTA={`Order and get $${discountAmount} off`}
                  queryString={`?referral-code=${referralCode}&utm_source=${referralCode}&utm_medium=physician&utm_campaign=physician-landing-page`}
                />
              </CTAButtonWrapper>
            </TriadItem>
            <TriadItem>
              <Img
                fluid={{
                  ...data.kitPlusCryoImage.childImageSharp.fluid
                }}
                alt="An image of the Fellow Analysis + Cryopreservation"
              />
              <ItemTextContainer>
                <ItemSubtitle>Analysis + Preservation</ItemSubtitle>
                <ItemTitle>
                  <span translate="no">Fellow </span>Analysis + Cryopreservation
                </ItemTitle>
                <ItemParagraph>
                  Includes the most accurate mail-in semen analysis and most rigorous
                  cryopreservation storage to ensure the protection of your fertility
                  for as long as you need.
                </ItemParagraph>
              </ItemTextContainer>
              <CTAButtonWrapper>
                <AddToCartButton
                  product={kitPlusCryoProduct}
                  CTA={`Order and get $${discountAmount} off`}
                  queryString={`?referral-code=${referralCode}&utm_source=${referralCode}&utm_medium=physician&utm_campaign=physician-landing-page`}
                />
              </CTAButtonWrapper>
            </TriadItem>
            <TriadItem id="vasectomy-test-triad-item">
              <Img
                fluid={{
                  ...data.vasectomyKitImage.childImageSharp.fluid
                }}
                alt="An image of the Fellow Vasectomy Test"
              />
              <ItemTextContainer>
                <ItemSubtitle>Sperm Present Check</ItemSubtitle>
                <ItemTitle>
                  Vasectomy Test
                </ItemTitle>
                <ItemParagraph>
                  Not trying to conceive? The<span translate="no"> Fellow </span>vasectomy test is the most
                  convenient way for you to test the success of your vasectomy
                  from home. Get doctor-approved results.
                </ItemParagraph>
              </ItemTextContainer>
              <CTAButtonWrapper>
                <AddToCartButton
                  product={vasectomyKitProduct}
                  CTA={`Order and get $${discountAmount} off`}
                  queryString={`?referral-code=${referralCode}&utm_source=${referralCode}&utm_medium=physician&utm_campaign=physician-landing-page`}
                />
              </CTAButtonWrapper>
            </TriadItem>
          </Triad>
        </Container>
      </Section>

      <Section hasBackground>
        <Container>
          <ContentWrapper>
            <SectionTitle>
              How it works:
            </SectionTitle>
            <StepWrapper>
              <Step>
                <StepContent>
                  <StepNumber>1</StepNumber>
                  <StepTitle>Purchase your kit.</StepTitle>
                  <StepDetails>
                    Buying your kit through this page applies a referral code
                    that gives you ${discountAmount} off and associates your kit
                    with{' '}
                    {physicianURL ? (
                      <a
                        href={physicianURL}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {physician}
                      </a>
                    ) : (
                      physician
                    )}{' '}
                    so that your results are sent to you both.
                  </StepDetails>
                </StepContent>
                <StepIcon>
                  <Img fluid={data.box.childImageSharp.fluid} />
                </StepIcon>
              </Step>
              <Step>
                <StepContent>
                  <StepNumber>2</StepNumber>
                  <StepTitle>Provide your sample & mail it back.</StepTitle>
                  <StepDetails>
                    Your kit will arrive in the mail with instructions and
                    everything you need to provide your sample and mail it back.
                  </StepDetails>
                </StepContent>
                <StepIcon>
                  <Img fluid={data.smallCup.childImageSharp.fluid} />
                </StepIcon>
              </Step>
              <Step>
                <StepContent>
                  <StepNumber>3</StepNumber>
                  <StepTitle>Get your secure results.</StepTitle>
                  <StepDetails>
                    When we get your sample, we analyze it in our CLIA certified
                    lab. We’ll send you and{' '}
                    {physicianURL ? (
                      <a
                        href={physicianURL}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {physician}
                      </a>
                    ) : (
                      physician
                    )}{' '}
                    a secure link to view your results.
                  </StepDetails>
                </StepContent>
                <StepIcon>
                  <Img fluid={data.results.childImageSharp.fluid} />
                </StepIcon>
              </Step>
            </StepWrapper>
          </ContentWrapper>
          <CenteredCTAButtonWrapper>
            {/* TODO: Add How-To Video CTA here */}
          </CenteredCTAButtonWrapper>
        </Container>
      </Section>
    </Layout>
  )
}

export default PhysicianLandingPage
